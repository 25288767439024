@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt217-card-banner {
    $root: &;
    display: flex;
    flex-flow: column;
    border-radius: rem(12px);
    overflow: hidden;

    @include respond-above(l) {
        flex-flow: row;
        justify-content: space-between;
    }

    & > div:first-child {
        @include respond-below(l) {
            flex-grow: 1;
        }
        @include respond-above(l) {
            max-width: 60%;
        }
    }

    &__title {
        @extend %robotoFontMedium;
        font-size: rem(32px);
        line-height: rem(40px);
        padding: rem(48px) rem(16px) rem(16px);
        color: var(--color-darkCharcoal);
        
        @include respond-above(l) {
            font-size: rem(40px);
            line-height: rem(48px);
            padding: rem(56px) rem(24px) rem(16px);
        }
    }

    &__description {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        padding: 0 rem(16px) rem(16px);

        @include respond-above(l) {
            padding: 0 rem(24px) rem(32px);
        }
    }

    &__validity {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        padding: 0 rem(16px) rem(22px);

        @include respond-above(l) {
            padding: 0 rem(24px) rem(16px);
        }
    }

    &__link {
        background-color: transparent;
        color: var(--color-black);
        border-color: var(--color-black);
        display: block;
        margin: 0 rem(16px) rem(24px);

        @include respond-above(l) {
            margin: 0 rem(24px) rem(24px);
        }
    }
    &__link:hover {
        color: var(--color-black);
        border-color: var(--color-black);
    }
    &__title + &__link {
        margin-top: rem(24px);
    }

    /* variant "standard" = "large" = "big" */
    &[data-variant="standard"],
    &[data-variant="large"],
    &[data-variant="big"] {
        
        #{$root}__image {
            max-width: rem(295.2px);
            width: 100%;
            height: rem(261px);
            align-self: flex-end;

            @include respond-above(l) {
                max-width: rem(375px);
                width: 100%;
                height: rem(345px);
            }
        }
    }

    /* variant "small" */
    &[data-variant="small"] {

        #{$root}__image {
            max-width: rem(295px);
            width: 100%;
            height: rem(188px);
            align-self: flex-end;

            @include respond-above(l) {
                max-width: rem(375px);
            }
        }

        #{$root}__title {
            padding: rem(23px) rem(16px) rem(16px);
            
            @include respond-above(l) {
                padding: rem(23px) rem(23px) rem(16px);
            }
        }

        #{$root}__textsWrapper {
            &--extraPadding {
                padding-top: rem(8px); 
        
                @include respond-above(l) {
                    padding-top: rem(16px); 
                }
            }
        }
    }
}